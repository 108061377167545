import React from 'react';
import { Modal, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { deleteChart } from '../features/MyCharts/MyChartsSlice'; // Import the deleteChart thunk
import '../css/MyCharts/deleteChart.css';

const DeleteChartModal = ({ visible, onCancel, chartData }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteChart(chartData.chart_id)); // Dispatch the delete action with the chart_id
    onCancel(); // Close the modal after deletion
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel} className="cancel-button">
          Cancel
        </Button>,
        <Button key="confirm" type="primary" onClick={handleDelete} className="confirm-button">
          Delete
        </Button>,
      ]}
    >
      <p className="confirm-text">Are you sure you want to delete this chart?</p>
      <div className="content">
        <p><strong>Chart Name:</strong> <span className="chart-value">{chartData.chart_name}</span></p>
        <p><strong>Data Source:</strong> <span className="chart-value">{chartData.data_source}</span></p>
        <p><strong>Chart Type:</strong> <span className="chart-value">{chartData.chart_type}</span></p>
      </div>
    </Modal>
  );
};

export default DeleteChartModal;
