import React, { useState } from 'react';
import { Card, Button, notification } from 'antd';
import PieChart from './PieChart';
import LineChart from './LineChart';
import BarChart from './BarChart';
import "../../css/MyCharts/MyChartsBody.css";
import chart_refresh from "../../assets/SVG/icons/MyCharts/chart_refresh.svg";
import chart_delete from "../../assets/SVG/icons/MyCharts/chart_delete.svg";
import chart_drag from "../../assets/SVG/icons/MyCharts/chart_drag.svg";
import add_chart_plus from "../../assets/SVG/icons/MyCharts/add_chart_plus_sym.svg";
import line_chart_logo from "../../assets/SVG/icons/MyCharts/line.svg";
import bar_chart_logo from "../../assets/SVG/icons/MyCharts/bar.svg";
import pie_chart_logo from "../../assets/SVG/icons/MyCharts/pie.svg";
import AddChartModal from '../../Modal/AddChartModal';
import DeleteChartModal from '../../Modal/DeleteChartModal';
import { useDispatch } from 'react-redux';
import { refreshChart } from "../../features/MyCharts/MyChartsSlice";
import FullScreenModal from './FullScreenModal';

const MyChartsBody = ({ myChartData }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [chartToDelete, setChartToDelete] = useState(null);
  const [fullscreenChart, setFullscreenChart] = useState(null);
  const [showFullscreen, setShowFullscreen] = useState(false);
  const dispatch = useDispatch();

  const capitalizeWords = (text) => {
    return text?.split(' ').map(word => {
      return word.length > 2 ? word.charAt(0).toUpperCase() + word.slice(1) : word;
    }).join(' ');
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const monthNames = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.", "Jul.", "Aug.", "Sept.", "Oct.", "Nov.", "Dec."];
    return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  }

  const showModal = () => setIsModalVisible(true);
  const handleOk = () => setIsModalVisible(false);
  const handleCancel = () => setIsModalVisible(false);

  const showDeleteModal = (chart) => {
    setChartToDelete(chart); // Set the chart to delete
    setIsDeleteModalVisible(true); // Show the delete modal
  };

  const handleRefresh = (chart) => {
    dispatch(refreshChart(chart.chart_id));
    notification.success({
      message: 'Chart Refreshed',
      description: `${chart.chart_name} refreshed with the latest data`,
    });
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  const handleDeleteConfirm = () => {
    setIsDeleteModalVisible(false);
  };

  const processChartData = (chartData, chartType) => {
    if (!chartData || !chartData.length) {
      return { xAxisData: [], yAxisData: [] }; // Handle undefined or empty data
    }
    if (chartType !== "pie_chart") {
      const sortedChartData = [...chartData].sort((a, b) => a.x_axis - b.x_axis);
      const xAxisData = sortedChartData.map((item) => item.x_axis);
      const yAxisData = sortedChartData.map((item) => item.y_axis);
      return { xAxisData, yAxisData };
    } else {
      const sortedChartData = [...chartData].sort((a, b) => a.label - b.value);
      const xAxisData = sortedChartData.map((item) => item.label);
      const yAxisData = sortedChartData.map((item) => item.value);
      return { xAxisData, yAxisData };
    }
  };

  const handleFullscreen = async (chart) => {
    if (showFullscreen) {
      setFullscreenChart(null);
      setShowFullscreen(false);
    } else {
      await setFullscreenChart(chart);
      setShowFullscreen(true);
    }
  };

  const renderCharts = (data) => {
    return data.map((chart) => {
      if (!chart || !chart.chart_data) {
        return null; // Skip rendering this chart
      }

      const { xAxisData, yAxisData } = processChartData(chart.chart_data, chart.chart_type.toLowerCase());
      console.log("Rendering chart:", chart.chart_id, chart.chart_data); // Debugging statement

      // Determine logo based on chart type
      let logo;
      switch (chart.chart_type.toLowerCase()) {
        case "bar_chart":
          logo = bar_chart_logo;
          break;
        case "line_chart":
          logo = line_chart_logo;
          break;
        case "pie_chart":
          logo = pie_chart_logo;
          break;
        default:
          logo = null; // Default case if needed
      }

      return (
        <Card key={chart.chart_id} className="chart-card">
          <div className="chart-header">
            <div className="chart-logo-title">
              {logo && <img src={logo} alt={`${chart.chart_type} logo`} className="chart-logo" />}
              <h3 className="chart-title" style={{ marginLeft: '8px' }}>{chart.chart_name}</h3>
            </div>
            <div className="chart-actions">
              <Button size="small" className="chart-button-no-border">
                <img src={chart_refresh} alt="Refresh" onClick={() => handleRefresh(chart)} />
              </Button>
              <Button size="small" className="chart-button-no-border" onClick={() => showDeleteModal(chart)}>
                <img src={chart_delete} alt="Delete" />
              </Button>
              <Button size="small" className="chart-button-no-border" onClick={() => handleFullscreen(chart)}>
                <img src={chart_drag} alt="Fullscreen" />
              </Button>
            </div>
          </div>
          <div className="chart-info">
            Data Set: {capitalizeWords(chart.data_source)}
          </div>
          <div className="chart-time-info">
            {capitalizeWords(chart.duration.replace(/_/g, ' '))} from {capitalizeWords(formatDate(chart.last_refreshed?.split(",")[0]))}
          </div>
          <div className="chart-body">
            {chart.chart_type.toLowerCase() === "bar_chart" && <BarChart row={xAxisData} col={yAxisData} x_axis_label={chart.parameter_1.replace(/_/g, ' ')} y_axis_label={chart.parameter_2.replace(/_/g, ' ')} />}
            {chart.chart_type.toLowerCase() === "line_chart" && <LineChart row={xAxisData} col={yAxisData} x_axis_label={chart.parameter_1.replace(/_/g, ' ')} y_axis_label={chart.parameter_2.replace(/_/g, ' ')} />}
            {chart.chart_type.toLowerCase() === "pie_chart" && <PieChart row={xAxisData} col={yAxisData} values_label={chart.parameter_2.replace(/_/g, ' ')} />}
          </div>
          <h4 className="chart-refresh-time">
            <span className="refresh-label">Last refreshed: </span>
            <span className="refresh-value">{capitalizeWords(chart.last_refreshed)}</span>
          </h4>
        </Card>
      );
    });
  };

  return (
    <div className="chart-container">
      {renderCharts(myChartData)}
      <Card className="add-chart-card" onClick={showModal}>
        <img src={add_chart_plus} alt="Add Chart" className="chart-image" />
      </Card>
      <AddChartModal visible={isModalVisible} onCancel={handleCancel} onCreate={handleOk} />

      {chartToDelete && (
        <DeleteChartModal
          visible={isDeleteModalVisible}
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          chartData={chartToDelete}
        />
      )}

      {/* Fullscreen Modal */}
      <FullScreenModal
        visible={showFullscreen}
        fullscreenChart={fullscreenChart}
        handleFullscreen={handleFullscreen}
      />
    </div>
  );
};

export default MyChartsBody;
