import React, { useState, useEffect , useMemo} from 'react';
import clockIcon from '../../assets/SVG/icons/DemandAnalysis/clock-icon.svg';
import { useSelector } from "react-redux";

const currentPath = window.location.pathname;

const options = currentPath === '/products' ? [
    { filterType: "Future 2 Weeks", payload: "future_n_weeks&n=2" },
    { filterType: "Future 1 Months", payload: "future_n_months&n=1" },
    { filterType: "Future 3 Months", payload: "future_n_quarters&n=1" },
  ] : [
    { filterType: "Current Year", payload: "current_year" },
    { filterType: "Current Month", payload: "current_month" },
    { filterType: "Past 157 weeks", payload: "past_m_weeks&m=157" },
    { filterType: "Past 6 Months", payload: "past_m_months&m=6" },
    { filterType: "Future 6 Months", payload: "future_n_months&n=6" },
    { filterType: "Past 2 + 1 Future Years", payload: "past_m_future_n_years&m=2&n=1" },
    { filterType: "Past 3 + 1 Future Years", payload: "past_m_future_n_years&m=3&n=1" },
    { filterType: "Past 1 + 1 Future Years", payload: "past_m_future_n_years&m=1&n=1" },
    { filterType: "Past 1 Year", payload: "past_m_years&m=1" },
    { filterType: "Future 1 Year", payload: "future_n_years&n=1" },
    { filterType: "Future 2 Weeks", payload: "future_n_weeks&n=2" },
    { filterType: "Future 1 Months", payload: "future_n_months&n=1" },
    { filterType: "Future 3 Months", payload: "future_n_quarters&n=1" },
  ];

function capitalizeFirstLetter(word) {
    if (!word) return ''; // Handle empty or undefined strings
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

const removeFilterWord = (text) => {
    return text.replace(/Filter/i, '').trim();
};

const removeSFilterWord = (text) => {
    return text.replace(/filters/i, '').trim();
};
function CardsFilter({ isAssortment, isProduct }) {
    const { itemFilter, locationFilter, productFilter } = useSelector((state) => state.products || "Set Filter");
    const { levelData, globalFilters } = useSelector((state) => state.filter || "Set Filter");
    const demandAnalysisState = useSelector((state) => state.demandAnalysis || {});
    const assortmentState = useSelector((state) => state.assortment || {});
    const productState = useSelector((state) => state.products || {});

    
    const filteredDemandAnalysisState = useMemo(() => {
        // Destructure the `status` property out of the state
        const { status, ...rest } = demandAnalysisState;
        // Filter out keys that contain the string 'filter'
        const filteredKeys = Object.keys(rest).filter(key => key.toLowerCase().includes('filter'));
        // Create a new object with only the filtered keys
        return filteredKeys.reduce((acc, key) => {
            acc[key] = rest[key];
            return acc;
        }, {});
    }, [demandAnalysisState]);

    const filteredAssortmentState = useMemo(() => {
        // Destructure the `status` property out of the state
        const { status, ...rest } = assortmentState;
        // Filter out keys that contain the string 'filter'
        const filteredKeys = Object.keys(rest).filter(key => key.toLowerCase().includes('filter'));
        // console.log("filteredKeys",filteredKeys)
        // Create a new object with only the filtered keys
        return filteredKeys.reduce((acc, key) => {
            acc[capitalizeFirstLetter(key)] = rest[key];
            return acc;
        }, {});
    }, [assortmentState]);

    const filteredProductState = useMemo(() => {
        // Destructure the `status` property out of the state
        const { status, ...rest } = productState;
        // Filter out keys that contain the string 'filter'
        const filteredKeys = Object.keys(rest).filter(key => key.toLowerCase().includes('filter'));
        // Create a new object with only the filtered keys
        return filteredKeys.reduce((acc, key) => {
            acc[key] = rest[key];
            return acc;
        }, {});
    }, [productState]);

    const intialFilterdictionary = {}
    levelData.map((item)=>{
        intialFilterdictionary[capitalizeFirstLetter(item.name)]='All'
    })

    intialFilterdictionary['Country']='All'
    intialFilterdictionary['Seasons']='All'
    intialFilterdictionary['Product']='All'
    intialFilterdictionary['Location']='All'


    const [filters, setFilters] = useState(intialFilterdictionary);
    
    const handleClick = (key) => {
        // Example update - you can implement your own logic
        // setFilters(prevFilters => ({
        //     ...prevFilters,
        //     [key]: 'SomeValue' // Update the key with some value for demonstration
        // }));
    };

 console.log(globalFilters)
 const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    useEffect(() => {
        setFilters(prevFilters => {
            const updatedFilters = { ...prevFilters };
    
            Object.keys(filteredProductState).forEach(key => {
                const values = filteredProductState[key] || [];
                updatedFilters[capitalizeFirstLetter(removeFilterWord(key))] = Array.isArray(values) ? values.join(', ') : values != null ? String(values) : 'All';
            });
            return updatedFilters;
        });
    }, [filteredProductState]);

// Create filterData1 dynamically
const filterData1 = [
    levelData.reduce((acc, level) => {
        const key = capitalizeFirstLetter(level.name); // Capitalize the key
        const filterValue = globalFilters[level.name] || "All"; // Fetch the corresponding filter value

        // If filterValue is an array, join it. Otherwise, use the value itself.
        acc[key] = Array.isArray(filterValue) ? filterValue.join(", ") : filterValue;
        return acc;
    }, {
        Country: globalFilters.country ? globalFilters.country.join(", ") : "All"
    })
];

    const filterData2 = [
        levelData.reduce((acc, level) => {
            const key = capitalizeFirstLetter(level.name); // Capitalize the key
            const filterValue = globalFilters[level.name] || "All"; // Fetch the corresponding filter value
    
            // If filterValue is an array, join it. Otherwise, use the value itself.
            acc[key] = Array.isArray(filterValue) ? filterValue.join(", ") : filterValue;
            return acc;
        }, {
            Season: globalFilters.season ? globalFilters.season.join(", ") : "All"
        })
    ];

    const filterData3 = [
        levelData.reduce((acc, level) => {
            const key = capitalizeFirstLetter(level.name); // Capitalize the key
            const filterValue = globalFilters[level.name] || "All"; // Fetch the corresponding filter value
    
            // Include 'name' and 'brand' from levelData
            if (['brand'].includes(level.name)) {
                acc[key] = Array.isArray(filterValue) ? filterValue.join(", ") : filterValue;
            }
    
            // Manually add 'location' and 'item'
            acc['Product'] = Array.isArray(globalFilters.product) && globalFilters.product.length ? globalFilters.product.join(", ") : "All";
            acc['Location'] = Array.isArray(globalFilters.location) && globalFilters.location.length ? globalFilters.location.join(", ") : "All";

            return acc;
        }, {})
    ];
    
    // const filterData3 = [
        
    //     {
    //         Brand: filters.Brand,
    //         Item: filters.item,
    //         Location: filters.Location,
    //         // Duration: filters.Duration,
    //     }
    // ];

    const currentFilterData = isAssortment ? filterData2 : (isProduct ? filterData3 : filterData1);
    const currentFilterDuration = isProduct ? productFilter : filteredDemandAnalysisState.filter;

     console.log(currentFilterDuration)
    return (
        <div className='forecasted_data_middle'>
            {isAssortment ? '' : <div className='forecasted_data_middle_filters'>
                <img src={clockIcon} alt="clock-icon" />
                <div>{(options.find(option => option.payload == currentFilterDuration)).filterType}</div>
            </div>}
            {currentFilterData.map((filter, index) => (
    <React.Fragment key={index}>
        {Object.entries(filter).map(([key, value], entryIndex) => (
            <React.Fragment key={key}>
                {!(isAssortment && index === 0 && entryIndex === 0) && (
                    <div className='forecasted_data_vertical_line'></div>
                )}
                <div onClick={() => handleClick(key)} style={{ cursor: 'pointer' }}>
                    {key}: {value && value.length > 0 && value !== 'All' ? value : 'All'}
                </div>
            </React.Fragment>
        ))}
    </React.Fragment>
))}

        </div>
    );
}

export default CardsFilter;
