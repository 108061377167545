import React, { useState, useEffect } from 'react';
import { Card, Table, Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInventoryInsights, fetchAllItems, fetchAllLocations } from '../../features/InventoryInsights/InventoryInsightsSlice';
import forecastedataIcon from '../../assets/SVG/icons/DemandAnalysis/forecastedataicon.svg';
import CardsHeading from '../../components/CommonComponents/CardsHeadingComponent';

const { Option } = Select;

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

function InsightStocks() {
  const dispatch = useDispatch();
  const { levelData } = useSelector((state) => state.filter || {});
  const { inventoryInsightsData, allItemsData, allLocationsData, insightloading, error } = useSelector((state) => state.inventoryInsights);
  
  const [itemFilter, setItemFilter] = useState(null);
  const [locationFilter, setLocationFilter] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const columns = [
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item',
      onHeaderCell: () => ({
        style: { color: '#919191' }
      }),
      render: (text) => <div style={{ color: '#7B61FF' }}>{text}</div>,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      onHeaderCell: () => ({
        style: { color: '#919191' }
      }),
      render: (text) => <div style={{ color: '#7B61FF' }}>{text}</div>,
    },
    //{
    //  title: 'Overall Inventory',
    //  dataIndex: 'overall_inventory',
    //  key: 'overall_inventory',
    //  onHeaderCell: () => ({
    //    style: { color: '#919191' }
    //  }),
    //  render: (text) => <div style={{ color: '#7B61FF' }}>{text || '-'}</div>,
    //},
    {
      title: 'Sold',
      dataIndex: 'sales',
      key: 'sales',
      onHeaderCell: () => ({
        style: { color: '#919191' }
      }),
      render: (text) => <div style={{ color: '#7B61FF' }}>{text || '-'}</div>,
    },
    {
      title: 'In Stock',
      dataIndex: 'in_stock',
      key: 'in_stock',
      onHeaderCell: () => ({
        style: { color: '#919191' }
      }),
      render: (text) => <div style={{ color: '#7B61FF' }}>{text || '-'}</div>,
    },
    {
      title: 'Date',
      dataIndex: 'as_of_date',
      key: 'as_of_date',
      onHeaderCell: () => ({
        style: { color: '#919191' }
      }),
      render: (text) => <div style={{ color: '#7B61FF' }}>{text || '-'}</div>,
    },
  ];

  useEffect(() => {
    dispatch(fetchAllItems());
  }, [dispatch]);

  useEffect(() => {
    if (allItemsData?.items?.length > 0 && !itemFilter) {
      setItemFilter(allItemsData.items[0]);
    }
  }, [allItemsData, itemFilter]);

  useEffect(() => {
    if (itemFilter) {
      dispatch(fetchAllLocations({ "item_sku": itemFilter }));
      dispatch(fetchInventoryInsights({ "item_sku": itemFilter, "location": locationFilter }));
      setFilteredData(inventoryInsightsData || []);
    }
  }, [itemFilter, locationFilter, dispatch]);

  useEffect(() => {
    setFilteredData(inventoryInsightsData || []);
  }, [inventoryInsightsData]);

  const handleChange = (value, type) => {
    if (type === 'item') {
      setItemFilter(value);
      if (value) {
        dispatch(fetchInventoryInsights({ "item_sku": value, "location": locationFilter }));
      } else {
        setLocationFilter("");
        dispatch(fetchInventoryInsights({ "item_sku": "", "location": "" }));
      }
    } else if (type === 'location') {
      setLocationFilter(value);
      if (itemFilter) {
        dispatch(fetchInventoryInsights({ "item_sku": itemFilter, "location": value }));
      }
    }
  };

  return (
    <Card className='dashboard_card_containers' style={{ marginBottom: "20px" }}>
      <div className='forecasted_data_main'>
        <CardsHeading
          heading="Inventory Positions"
          headingicon={forecastedataIcon}
          dataTODownload={inventoryInsightsData}
          showFilter={false}
          componentType="inventorytype"
        />
        {insightloading ? (
          <Spin size="large" />
        ) : (
          <div>
            <Select
              style={{ width: 200, marginBottom: 16, margin: 10 }}
              placeholder="Select Item"
              onChange={(value) => handleChange(value, 'item')}
              showSearch
              optionFilterProp="children"
              value={itemFilter || undefined}
            >
              {(allItemsData?.items || []).map(item => (
                <Option key={item} value={item}>{item}</Option>
              ))}
            </Select>
            <Select
              style={{ width: 200, marginBottom: 16, margin: 10 }}
              placeholder="Select Location"
              onChange={(value) => handleChange(value, 'location')}
              allowClear
              showSearch
              optionFilterProp="children"
              value={locationFilter || undefined}
            >
              {(allLocationsData?.location || []).map(location => (
                <Option key={location} value={location}>{location}</Option>
              ))}
            </Select>
            <Table
              dataSource={filteredData.length || error ? filteredData : [{ item: '-', location: '-', overall_inventory: '-', sales: '-', in_stock: '-', as_of_date: '-' }]}
              columns={columns}
              pagination={{ pageSize: 5, showSizeChanger: false, hideOnSinglePage: true }}
              rowKey="id"
            />
          </div>
        )}
      </div>
    </Card>
  );
}

export default InsightStocks;




