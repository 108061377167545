import React from 'react';
import Chart from 'react-apexcharts';
import '../../css/BarChart.css'; // Import the external CSS file


function BarChart({ row, col, x_axis_label, y_axis_label }) {
    const capitalizeWords = (text) => {
        return text.split(' ').map(word => {
            return word.length > 2 ? word.charAt(0).toUpperCase() + word.slice(1) : word;
        }).join(' ');
    };

    const options = {
        chart: {
            type: 'bar',
            zoom: {
                enabled: true
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: col, // This will be your brand names
            title: {
                text: capitalizeWords(x_axis_label), // Capitalize x-axis label
                style: {
                    fontWeight: '300', // Reduced font weight
                    color: "#7B61FF",
                }
            },
            labels: {
                style: {
                    color: "#7B61FF", // Set color for x-axis labels
                }
            },
        },
        yaxis: {
            title: {
                text: capitalizeWords(y_axis_label), // Capitalize y-axis label
                style: {
                    fontWeight: '300', // Reduced font weight
                    color: "#7B61FF",
                }
            },
            labels: {
                formatter: (value) => `${value}`, // Formatting the label if needed
                style: {
                    color: "#7B61FF", // Set color for x-axis labels
                },
            }
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: (value) => `${value}`, // Formatting the tooltip if needed
            }
        }
    };

    // Ensure row (series) is in the correct format
    const series = [{
        data: row // This will be your total sales
    }];

    return (
        <div>
            <Chart options={options} series={series} type="bar" width="90%" />
        </div>
    );
}

export default BarChart;
