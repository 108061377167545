import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Card } from 'antd';
import '../../css/PieChart.css'; // Import the external CSS file

function PieChart({ row = [], col = [], values_label }) {
    const [expanded, setExpanded] = useState(false);
    const [colors, setColors] = useState([]);

    const toggleExpand = () => {
        setExpanded(prevExpanded => !prevExpanded);
    };

    const getRandomColor = () => {
        const r = Math.floor(Math.random() * 128 + 127); // Light red
        const g = Math.floor(Math.random() * 128 + 127); // Light green
        const b = Math.floor(Math.random() * 128 + 127); // Light blue
        return `rgb(${r}, ${g}, ${b})`;
    };

    useEffect(() => {
        setColors(col.map(() => getRandomColor()));
    }, [col]);

    const options = {
        chart: {
            type: 'donut',
            width: '100%',
            height: '80%', // Adjusted height for the chart
        },
        labels: row,
        colors: colors,
        plotOptions: {
            pie: {
                donut: {
                    size: '50%', // Adjust the donut size
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '8px',
                        fontWeight: 'light',
                        colors: ['#7B61FF'],
                    },
                    formatter: (val, opts) => {
                        return `${opts.w.globals.labels[opts.seriesIndex]}: ${val.toFixed(2)}%`;
                    },
                },
            },
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '8px',
            labels: {
                colors: '#7B61FF',
            },
        },
        title: {
            text: `${values_label}`,
            align: 'center',
            style: {
                fontSize: '14px',
                color: '#7B61FF',
            },
        },
    };
    
    const series = col.map(value => parseFloat(value));

    return (
       
        <div className="pie-chart-container">
            <Chart options={options} series={series} type="donut" />
        </div>
            
    );
}

export default PieChart;
