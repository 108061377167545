import React from 'react';
import Chart from 'react-apexcharts';
import '../../css/LineChart.css'; // Import the external CSS file


function LineChart({ row, col, x_axis_label, y_axis_label }) {
    const capitalizeWords = (text) => {
        return text.split(' ').map(word => {
            return word.length > 2 ? word.charAt(0).toUpperCase() + word.slice(1) : word;
        }).join(' ');
    };

    const options = {
        chart: {
            type: 'line',
            zoom: {
                enabled: true
            },
            animations: {
                enabled: true,
            },
            toolbar: {
                show: true,
            },
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        markers: {
            size: 3, // Size of the point markers
            colors: ['#ffffff'], // Marker color
            strokeColors: ['#7B61FF'], // Marker stroke color
            strokeWidth: 2,
            hover: {
                size: 7 // Enlarged size on hover
            }
        },
        xaxis: {
            categories: row,
            title: {
                text: capitalizeWords(x_axis_label),
                style: {
                    fontWeight: '500', // Increased weight for prominence
                    color: "#7B61FF",
                    fontSize: '16px', // Increased font size
                },
            },
            labels: {
                style: {
                    fontSize: '14px', // Adjust label font size
                    colors: ['#333'], // Label color
                },
                formatter: (value) => value, // Customize label formatting if needed
            },
            axisTicks: {
                show: false, // Hide Y-axis ticks
            },
            axisBorder: {
                show: false, // Hide Y-axis border
            },
            grid: {
                show: false,
            },
        },
        yaxis: {
            title: {
                text: capitalizeWords(y_axis_label),
                style: {
                    fontWeight: '500', // Increased weight for prominence
                    color: "#7B61FF",
                    fontSize: '16px', // Increased font size
                },
            },
            labels: {
                style: {
                    fontSize: '14px', // Adjust label font size
                    colors: ['#333'], // Label color
                },
                formatter: (value) => value, // Customize label formatting if needed
            },
            axisTicks: {
                show: false, // Hide Y-axis ticks
            },
            axisBorder: {
                show: false, // Hide Y-axis border
            },
            grid: {
                show: true, // Show grid lines for the Y-axis
                borderColor: '#e0e0e0', // Color of the grid lines
                strokeDashArray: 5, // Make the grid lines dashed
                opacity: 0.7, // Adjust the opacity of the grid lines
                position: 'back',
            },
        },
        tooltip: {
            enabled: true,
            style: {
                fontSize: '12px',
                fontWeight: '400',
            },
            x: {
                show: true,
                // formatter: (value) => `Date: ${value}`, // Custom tooltip formatting
            },
            y: {
                formatter: (value) => `Value: ${value}`, // Custom tooltip formatting
            },
            marker: {
                show: true,
            },

        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            floating: false,
            labels: {
                colors: '#7B61FF',
            },
        },
    };

    const series = [{
        data: col
    }];

    
    return (
        <div>
            <Chart options={options} series={series} type="line" width="90%" />
        </div>
    );
}

export default LineChart;
