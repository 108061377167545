import React from 'react';

function ForecastGraphFilters({ handleSpecialDayHover, holidayList = [] }) {
    const graphInfoColumn1 = [
        { name: 'Actual', color: '#7B61FF', style: 'solid' },
        { name: 'Bestfit Forecast', color: '#FF9F43', style: 'dotted' },
        { name: 'Prophet', color: '#4BC0C0', style: 'dotted' },
        { name: 'ETS', color: '#FF6384', style: 'solid' }
    ];
    
    const graphInfoColumn2 = [
        { name: 'Arima', color: '#36A2EB', style: 'solid' },
        { name: 'Moving Average', color: '#FFA07A', style: 'solid' }
    ];

    const getRandomColor = () => {
        // You can add more colors if needed
        return '#7B61FF'; 
    };

    const handleDayHover = (day) => {
        handleSpecialDayHover(day);
    };

    const renderGraphInfo = (graphInfo) => (
        graphInfo.map((info) => (
            <div key={info.name} style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                <div
                    style={{
                        width: '30px',
                        height: '2px',
                        borderBottom: `2px ${info.style} ${info.color}`,
                        marginRight: '8px'
                    }}
                ></div>
                <div style={{ fontSize: '14px' }}>{info.name}</div>
            </div>
        ))
    );

    return (
        <div className='graph-filters-main' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 0 }}>
            <div className='graph-info-container' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                {renderGraphInfo(graphInfoColumn1)}
                {renderGraphInfo(graphInfoColumn2)}
            </div>

            {/* Holiday list filter */}
            {holidayList.length > 0 && (
                <div className='holiday-list-filter' style={{ marginTop: '10px', width: '100%' }}>
                    <ul style={{ listStyleType: 'none', padding: 0, margin: 0, display: 'flex', justifyContent: 'space-between' }}>
                        {holidayList.map((holiday, index) => (
                            <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '0' }}>
                                <div style={{
                                    width: '15px',
                                    height: '15px',
                                    backgroundColor: getRandomColor(), 
                                    marginRight: '10px'
                                }}></div>
                                <div style={{ fontSize: '14px' }}>{holiday}</div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default ForecastGraphFilters;
