import React, { useState, useEffect } from "react";
import filterIcon from "../../assets/SVG/Demand_Analysis/filters.svg";
import clearIcon from "../../assets/SVG/Demand_Analysis/clearAll.svg";
import downArrowIcon from "../../assets/SVG/Demand_Analysis/downIcon.svg";
import SelectDurationModal from "./SelectDurationModal";
import BrandModal from "../../Modal/BrandModal";
import AssortmentModal from "../../Modal/AssortmentModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Card } from 'antd';
import { demandAnalysisData, demandAnalysisPlot } from "../../features/DemandAnalysis/DemandAnalysisSlice";
import { assortmentPlotApi, assortmentTableDataApi } from "../../features/Assortment/AssortmentSlice";
import { fetchSimilarItems, fetchChartData } from "../../features/Product/ProductSlice";
import { filter } from "lodash";
import { drodownDataApi, getCountries, getSeasons,resetGlobalFilters } from "../../features/Filters/FiltersSlice";
import { levelDataApi } from '../../features/Filters/FiltersSlice';


const InSeasonAllocationHeader = ({ onReset }) => {
  const [loading, setLoading] = useState(true);
  const [dataModalVisible, setDataModalVisible] = useState(false);
  const [buttonType, setButtonType] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { levelData, globalFilters } = useSelector((state) => state.filter || []);
  
  // console.log(globalFilters)

  // Simulating loading state
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    dispatch(levelDataApi());
    dispatch(drodownDataApi({}));
  }, []);


  // Handling button click to open modal
  const handleButtonClick = (buttonType) => {
    let modalButtonType = buttonType;
    // Determine modal button type based on current path
    switch (location.pathname) {
      
      case "/demand-analysis":
        if (buttonType === "country") {
          dispatch(getCountries())
        }
        modalButtonType = buttonType;
        break;
      case "/products":
        modalButtonType = ["Brand","Product", "Location"].includes(buttonType) ? buttonType : "Product";
        break;
      case "/assortment":
        if (buttonType === "season") {
          dispatch(getSeasons())
        }
        modalButtonType = buttonType;
        break;
      default:
        modalButtonType = "Brand";
        break;
    }

    setButtonType(modalButtonType);
    setDataModalVisible(true);
  };

  // Close modal function
  const handleModalClose = () => {
    setDataModalVisible(false);
  };

  // Clear all filters function
  const handleClearAll = async () => {
    console.log("window.location.pathname",window.location.pathname)
    switch (window.location.pathname) {
      case '/demand-analysis':
        dispatch(resetGlobalFilters());
        await dispatch(demandAnalysisData({ filter: "", filterName: "" }));
        await dispatch(demandAnalysisPlot({ filter: "", filterName: "" }));
        break;
      case '/products':
        dispatch(resetGlobalFilters());
        await dispatch(fetchSimilarItems({ filter: "", filterName: "" }));
        await dispatch(fetchChartData({ filter: "", filterName: "" }));
        break;
      case '/assortment':
        dispatch(resetGlobalFilters());
        await dispatch(assortmentTableDataApi({ filter: "", filterName: "" }));
        await dispatch(assortmentPlotApi({ filter: "", filterName: "" }));
        break;
      default:
        // console.log("No Path");
        break;
    
    }

};


  // Filtering input fields based on current path

  // Dynamically generating demand level options based on levelData
  const demandLevelsOptions = levelData.map(level => {
    const levelKey = level.name.toLowerCase();  // Convert the level name to lowercase for case-insensitive comparison
    const globalFilterValues = globalFilters[levelKey];  // Find the corresponding key in globalFilters

    return {
      label: level.name.charAt(0).toUpperCase() + level.name.slice(1), // Capitalize the name
      value: level.name.charAt(0).toUpperCase() + level.name.slice(1),
      selectAs: globalFilterValues ? globalFilterValues.join(', ') : '',  // Join the values for that key, or return an empty string if not found
    };
  });

  const countryFilterData = {
    label: 'Country', // Capitalize the name
    value: 'Country',
    selectAs: globalFilters['country'] ? globalFilters['country'].join(', ') : '',
  };

  const seasonFilterData = {
    label: 'Season', // Capitalize the name
    value: 'Season',
    selectAs: globalFilters['season'] ? globalFilters['season'].join(', ') : '',
  };
  
  const locationFilterData = {
    label: 'Location', // Capitalize the name
    value: 'Location',
    selectAs: globalFilters['location'] ? globalFilters['location'].join(', ') : '',
  };

  const productFilterData = {
    label: 'Product', // Capitalize the name
    value: 'Product',
    selectAs: globalFilters['product'] ? globalFilters['product'].join(', ') : '',
  };


  let inputFields = [];
  // console.log(inputFields)
  // console.log("allInputFields.filter(field => [].includes(field.value))",allInputFields.filter(field => ["Items", "Location"].includes(field.value)))
  switch (location.pathname) {
    case "/demand-analysis":
      inputFields = demandLevelsOptions;
      break;
    case "/assortment":
      inputFields = demandLevelsOptions;
      break;
    case "/products":
      demandLevelsOptions.push(
        productFilterData,
        locationFilterData,
      );
      inputFields = demandLevelsOptions.filter(field => ["Brand","Product", "Location"].includes(field.value));
      break;
    default:
      inputFields = [];
  }

  // Save inputFields to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("inputFields", JSON.stringify(inputFields));
  }, [inputFields]);

  return (
    <Card
      className='dashboard_card_containers'
      loading={loading}
      style={{ marginBottom: "20px" }}
    >
      {/* Filter section */}
      <div className="Demand_Analysis_filter">
        <div className="top1">
          <div className="top1_left">
            <div className="top1_left_filterContainer">
              {/* Filter icon */}
              <img src={filterIcon} alt="filter-icon" />
              {/* Filter text */}
              <p className="filterContainer_para">Filters</p>
            </div>
          </div>
          <div className="top1_right">
            {/* Conditionally render SelectDurationModal based on location */}
            {location.pathname !== "/assortment" && <SelectDurationModal />}
            {/* Clear all button */}
            <button onClick={handleClearAll} className="clearAll_Button" style={{ border: "none", backgroundColor: "white", color: "red", cursor: "pointer" }}>
              <img src={clearIcon} alt="clear-icon" />Clear All
            </button>
            {/* Down arrow icon */}
            <img style={{ marginRight: "10px" }} src={downArrowIcon} alt="down-arrow-icon" />
          </div>
        </div>
        {/* Filter options */}
        <div className="top2">
          {inputFields.map(field => (
            <div key={field.value} className="top2_field">
              {/* Label */}
              <p style={{ color: "#7B61FF", fontWeight: "600", fontSize: "15px" }}>{field.label}</p>
              {/* Button with selectAs text */}
              <button style={{ cursor: "pointer", height: "40px" }} onClick={() => handleButtonClick(field.value)}>
                {field?.selectAs.length > 0 ? field?.selectAs : "All"}
              </button>
            </div>
          ))}


          {window.location.pathname === "/demand-analysis" ?

            <div key='country' className="top2_field">
              {/* Label */}
              <p style={{ color: "#7B61FF", fontWeight: "600", fontSize: "15px" }}>Country</p>
              {/* Button with selectAs text */}
              <button style={{ cursor: "pointer", height: "40px" }} onClick={() => handleButtonClick('country')}>
                {countryFilterData?.selectAs?.length > 0 ? countryFilterData?.selectAs : "All"}
              </button>
            </div> :""
            
          }
          {window.location.pathname === "/assortment" ?
            <div key='season' className="top2_field">
              {/* Label */}
              <p style={{ color: "#7B61FF", fontWeight: "600", fontSize: "15px" }}>Season</p>
              {/* Button with selectAs text */}
              <button style={{ cursor: "pointer", height: "40px" }} onClick={() => handleButtonClick('season')}>
                {seasonFilterData?.selectAs?.length > 0 ? seasonFilterData?.selectAs : "All"}
              </button>
            </div>:""
          }

        </div>
      </div>
      {/* Render modal based on visibility and buttonType */}
      {dataModalVisible && buttonType && (
        <>
          {/* {location.pathname === '/assortment' ? (
            <AssortmentModal visible={dataModalVisible} closeModal={handleModalClose} buttonType={buttonType} />
          ) : ( */}
          <BrandModal visible={dataModalVisible} closeModal={handleModalClose} buttonType={buttonType} />
          {/* )} */}
        </>
      )}
    </Card>
  );
};

export default InSeasonAllocationHeader;
