import React, { useState, useEffect } from 'react';
import { Modal, Spin } from 'antd';
import PieChart from './PieChart';
import LineChart from './LineChart';
import BarChart from './BarChart';

const FullScreenModal = ({ visible, fullscreenChart, handleFullscreen }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (visible && fullscreenChart) {
      setLoading(true);
      setError(false);
      // Simulate loading the chart data
      const loadData = async () => {
        try {
          // Simulate a delay and potential error
          await new Promise((resolve, reject) => {
            const hasError = Math.random() < 0.3; // 30% chance to simulate an error
            setTimeout(() => (hasError ? reject(new Error('Failed to load')) : resolve()), 1000);
          });
        } catch {
          setError(true);
        } finally {
          setLoading(false);
        }
      };
      loadData();
    }
  }, [visible, fullscreenChart]);

  const retryLoad = () => {
    setLoading(true);
    setError(false);
  };
  
  
  return (
    <Modal
      visible={visible}
      footer={null}
      width="60%"
      onCancel={() => handleFullscreen(null)}
      centered
      bodyStyle={{ height: '70vh' }}
    >
      {loading && <Spin size="large" />}
      {error && (
        <div>
          <p>Error loading chart data. Please try again.</p>
          <button onClick={retryLoad}>Retry</button>
        </div>
      )}
      {fullscreenChart && !loading && !error && (
        <div className="chart-body">
          {fullscreenChart.chart_type.toLowerCase() === "bar_chart" && (
            <BarChart
              row={fullscreenChart.chart_data.map((item) => item.x_axis)}
              col={fullscreenChart.chart_data.map((item) => item.y_axis)}
              x_axis_label={fullscreenChart.parameter_1.replace(/_/g, ' ')}
              y_axis_label={fullscreenChart.parameter_2.replace(/_/g, ' ')}
            />
          )}
          {fullscreenChart.chart_type.toLowerCase() === "line_chart" && (
            <LineChart
              row={fullscreenChart.chart_data.map((item) => item.x_axis)}
              col={fullscreenChart.chart_data.map((item) => item.y_axis)}
              x_axis_label={fullscreenChart.parameter_1.replace(/_/g, ' ')}
              y_axis_label={fullscreenChart.parameter_2.replace(/_/g, ' ')}
            />
          )}
          {fullscreenChart.chart_type.toLowerCase() === "pie_chart" && (
            <PieChart
              row={fullscreenChart.chart_data.map((item) => item.label)}
              col={fullscreenChart.chart_data.map((item) => item.value)}
              values_label={fullscreenChart.parameter_2.replace(/_/g, ' ')}
            />
          )}
        </div>
      )}
    </Modal>
  );
};

export default FullScreenModal;
